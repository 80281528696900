<template>
    <div class="">
        <div class="image_box">
            <img :src="copyRight.business_license" alt="" />
        </div>
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        name: "licence",
        created() {
            this.$store.dispatch("site/copyRight")
            console.log(".......copyRight.", this.copyRight)
        },
        computed: {
            ...mapGetters(["copyRight"])
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .image_box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 80vw;
            height: auto;
        }
    }
</style>
